.has-background-transparent {
    background-color: rgba(0,0,0,0);
}
.hero-head {
    margin: 1rem;
}
.hero-foot .columns {
    margin: 0 1rem !important;
}
html {
    overflow-y: auto;
}